import { InputMask } from "@react-input/mask";
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import TutorialCNPJ from "../assets/images/tutorial-cnpj.jpg";
import TutorialNumero from "../assets/images/tutorial-numero.jpg";
import TutorialSerie from "../assets/images/tutorial-serie.jpg";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";
import { validateEmptyValue, validatePromotionDate } from "../utils";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [cupomId, setCupomId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPremium, setShowPremium] = useState(false);

  const app = useContext(AppContext);
  const api = useApi();

  const [options, setOptions] = useState<any[]>([]);
  const [products, setProducts] = useState<Map<string, any>>(new Map());
  const [showTutorial, setShowTutorial] = useState("");
  const [isShowingProductModal, setIsShowingProductModal] = useState(false);

  const handleShowTutorial = (tutorial: string) => () => {
    setShowTutorial(tutorial);
  };

  const { register, handleSubmit, setValue, setError, clearErrors, formState } =
    useForm({
      defaultValues: {
        cnpj: "",
        numero_cupom_fiscal: "",
        serie_cupom_fiscal: "",
        data_compra: "",
        valor_compra: "R$ 0,00",
        recaptcha_token: "",
        produtos: {},
      },
    });

  const handleSuccess = useCallback((response: any) => {
    Swal.close();

    if (response.data?.premio) {
      setCupomId(response.data?.premio);
    }

    setShowModal(true);
  }, []);

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "Erro ao carregar o reCAPTCHA",
        });

        return;
      }

      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando o seu cupom",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.recaptcha_token = await executeRecaptcha();

      api.registerCupom(data).then(handleSuccess).catch(handleError);
    },
    [api, executeRecaptcha, handleError, handleSuccess],
  );

  const handleParticipationLimit = useCallback(
    (response: any) => {
      if (response.data?.pode_participar === "N") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html:
            response.message ||
            'Você atingiu o limite de participação. <a class="text-primary" href="/regulamento">Consulte o regulamento</a> para mais informações.',
          confirmButtonText: "Ver meu extrato",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(() => {
          navigate("/meus-numeros");
        });
      } else {
        Swal.close();
      }
    },
    [navigate],
  );

  const handleQuantity = ({ id, label }: any, quantity: number) => {
    if (products.has(id)) {
      const currentQuantity = products.get(id)?.quantity ?? 0;

      if (currentQuantity + quantity > 0) {
        setProducts(
          new Map(
            products.set(id, {
              id: Number(id),
              label,
              quantity: currentQuantity + quantity,
            }),
          ),
        );
      } else {
        products.delete(id);
        setProducts(new Map(products.entries()));
      }
    } else {
      if (quantity > 0) {
        setProducts(
          new Map(products.set(id, { id: Number(id), label, quantity })),
        );
      }
    }
  };

  useEffect(() => {
    Swal.fire({
      title: "Aguarde...",
      text: "Estamos carregando as informações",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    api
      .getParticipationLimit()
      .then(handleParticipationLimit)
      .catch(handleParticipationLimit);
  }, [api, handleParticipationLimit]);

  useEffect(() => {
    const data = Array.from(products.values()).reduce(
      (all, { id, quantity }) => [...all, { id, quantidade: quantity }],
      [],
    );

    setValue<any>("produtos", data);
    clearErrors("produtos");
  }, [products, setValue, clearErrors]);

  useEffect(() => {
    api.getProdutos().then((response) => {
      setOptions(
        Object.entries(response.data).map(([id, label]) => ({ id, label })),
      );
    });
  }, [api]);

  useEffect(() => {
    register("produtos", {
      required: "Este campo é obrigatório",
      validate: () => products.size > 0,
    });
  }, [register, products]);

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">CADASTRAR CUPOM</h1>

          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="cnpj">
                      CNPJ da Loja*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialCNPJ)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="__.___.___/____-__"
                      placeholder="__.___.___/____-__"
                      replacement={{ _: /\d/ }}
                      id="cnpj"
                      isInvalid={!!formState.errors.cnpj}
                      {...register("cnpj", {
                        required: "Este campo é obrigatório",
                      })}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formState.errors.cnpj?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="numero_cupom_fiscal">
                      Número do cupom fiscal*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialNumero)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      id="numero_cupom_fiscal"
                      isInvalid={!!formState.errors.numero_cupom_fiscal}
                      {...register("numero_cupom_fiscal", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          setValue(
                            "numero_cupom_fiscal",
                            event.target.value.replace(/\D/g, ""),
                          );
                        },
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.numero_cupom_fiscal?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="serie_cupom_fiscal">
                      Série do cupom fiscal*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialSerie)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      id="serie_cupom_fiscal"
                      isInvalid={!!formState.errors.serie_cupom_fiscal}
                      {...register("serie_cupom_fiscal", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          setValue(
                            "serie_cupom_fiscal",
                            event.target.value.replace(/\D/g, ""),
                          );
                        },
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.serie_cupom_fiscal?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="data_compra">
                      Data da compra*
                    </Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="__/__/____"
                      placeholder="__/__/____"
                      replacement={{ _: /\d/ }}
                      id="data_compra"
                      isInvalid={!!formState.errors.data_compra}
                      {...register("data_compra", {
                        required: "Este campo é obrigatório",
                        validate: validatePromotionDate(
                          import.meta.env.VITE_PARTICIPATION_START_DATE,
                          import.meta.env.VITE_PARTICIPATION_END_DATE,
                          "Data de compra fora do período de participação",
                        ),
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.data_compra?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="valor_compra">
                      R$ Valor da compra*
                    </Form.Label>
                    <Form.Control
                      id="valor_compra"
                      isInvalid={!!formState.errors.valor_compra}
                      onClick={(event: any) => {
                        event.target.setSelectionRange(
                          event.target.value.length,
                          event.target.value.length,
                        );
                      }}
                      {...register("valor_compra", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          event.target.setSelectionRange(
                            event.target.value.length,
                            event.target.value.length,
                          );

                          let value = event.target.value
                            .replace(/\D/g, "")
                            .replace(/^0+/, "");

                          value = value
                            .padStart(3, "0")
                            .replace(/(\d{2})$/, ",$1")
                            .replace(/(?=(\d{3})+(\D))\B/g, ".");

                          setValue("valor_compra", `R$ ${value}`);
                        },
                        validate: validateEmptyValue(),
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.valor_compra?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12}>
                    <Form.Group controlId="formName" className="mb-3">
                      <Form.Label>Produtos no cupom fiscal *</Form.Label>
                      <Table striped bordered>
                        <tbody>
                          {[...products.entries()].map(([, product]) => (
                            <tr key={product.id}>
                              <td>{product.label}</td>
                              <td>{product.quantity}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan={2} className="text-center">
                              <Button
                                onClick={() => setIsShowingProductModal(true)}
                                size="sm"
                              >
                                {products.size === 0 ? (
                                  <>
                                    <i className="bi bi-plus-circle-fill me-2"></i>
                                    <span>Adicionar produtos</span>
                                  </>
                                ) : (
                                  <>
                                    <i className="bi bi-pencil-square me-2"></i>
                                    <span>Editar produtos</span>
                                  </>
                                )}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {formState.errors.produtos && (
                        <span className="text-small">
                          {formState.errors.produtos?.message ||
                            "Selecione pelo menos um produto"}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {formState.errors.recaptcha_token && (
                  <Alert variant="danger" className="mb-3">
                    {formState.errors.recaptcha_token.message}
                  </Alert>
                )}

                <div className="d-flex my-5">
                  <Button
                    type="submit"
                    variant="primary"
                    className="button btn-tint mx-auto"
                  >
                    CADASTRAR CUPOM
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={isShowingProductModal} contentClassName="modal-products">
        <Modal.Body>
          <p className="text-center fw-bold">Produtos no cupom fiscal</p>
          <Table className="modal-products__list">
            <tbody>
              {options.map((option) => (
                <tr key={option.label}>
                  <td>{option.label}</td>
                  <td>
                    <div className="quantity">
                      <i
                        className="bi bi-dash-circle-fill me-2"
                        onClick={() => handleQuantity(option, -1)}
                      ></i>
                      <Form.Control
                        placeholder="0"
                        value={products.get(option.id)?.quantity ?? 0}
                      />
                      <i
                        className="bi bi-plus-circle-fill ms-2"
                        onClick={() => handleQuantity(option, 1)}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="modal-products__action d-flex align-items-center justify-content-center">
            <Button
              type="submit"
              variant="primary"
              onClick={() => setIsShowingProductModal(false)}
              size="sm"
            >
              <i className="bi bi-x-circle me-2"></i>
              <span>Fechar</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="custom-modal" show={!!showTutorial}>
        <Modal.Body>
          <Stack className="align-items-center">
            <Image className="mb-4" src={showTutorial} alt="" width={300} />

            <Button
              className="btn-tint button"
              onClick={handleShowTutorial("")}
            >
              OK
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>

      <Modal className="custom-modal" show={showModal}>
        <Modal.Body>
          <h1 className="d-flex justify-content-center">
            <span>Parabéns!</span>
          </h1>

          <p className="text-center mt-5">
            Você está concorrendo ao prêmio final.
            <br />
            Veja seus números da sorte na aba Meus Números
          </p>

          <div className="d-flex">
            <Button
              onClick={() => {
                setShowModal(false);

                if (cupomId) {
                  setShowPremium(true);
                } else {
                  navigate("/meus-numeros");
                }
              }}
              type="submit"
              variant="primary"
              className="button btn-tint mx-auto"
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="custom-modal" show={showPremium}>
        <Modal.Body>
          <h1 className="d-flex justify-content-center">
            <span>Parabéns, você ganhou!</span>
          </h1>

          <p className="text-center mt-5">
            Você ganhou o prêmio instantâneo.
            <br />
            Você será redirecionado para a página de envio de nota fiscal.
          </p>

          <div className="d-flex">
            <Button
              onClick={() => {
                setShowModal(false);
                setShowPremium(false);
                navigate(`/cupom/${cupomId}`);
              }}
              type="submit"
              variant="primary"
              className="button btn-tint mx-auto"
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Suspense>
  );
}
